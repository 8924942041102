.App {
  text-align: center;
  font-family: "Roboto";
}

button {
  outline: none !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, .2) !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

nav {
  background-color: #2279f5;
  padding: 1rem 1rem;
}

.navbar3 {
  display: flex;
  align-items: center;
  background: linear-gradient(360deg, #2279F5 8.33%, #00D2FE 91.67%);
}

.navbar1 {
  display: flex;
  justify-content: flex-start;
}

.navbar1 img {
  margin: 5px;
}

.brand-txt {
  margin-left: 2%;
  color: #fff;
}

.txt1 {
  font-weight: bold;
  letter-spacing: 1px;
}

.txt2 {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: x-small;
  margin-left: 3%;
}

.txt3 {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 8px;
}

.navbar2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.avatar {
  display: inline-block;
  margin-bottom: 0;
  height: 3rem;
  width: 3rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-right: 15px;
}

.rounded-circle {
  border-radius: 50% !important;
}

.avatar-nav {
  height: 2.2rem;
  width: 2.2rem;
  margin-right: 10px;
}

.out-box {
  height: 685px;
  max-width: 1366px;
  border-radius: 12px;
  margin: 40px auto;
  background: url("./Images/homescreen.png"), linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%);
}

@media only screen and (max-width: 1440px) {
  .out-box {
    height: 700px;
  }

}

.contain {
  height: calc(2 * 328);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
}

.txt-container {
  width: 800px;
  height: 250px;

  display: flex;
  align-items: center;
  align-items: center;
  flex-direction: column;
  max-height: 100vh;
}

.box-container {
  width: 800px;
  height: 328px;
  border-radius: 12px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-height: 100vh;
  opacity: 1;
  background-color: #ffff;
}

.txts {
  margin-top: 80px;
  margin-right: 144px;
}

.box {
  width: 100%;
  max-width: 200px;
  height: 168px;
  padding: 28px, 40px, 28px, 40px;
  gap: 10px;
  box-shadow: 0px 2px 40px rgba(34, 121, 245, 0.15);
  border-radius: 12px;
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.box:hover {
  transform: scale(1.05);
  background-image: linear-gradient(#2279f5, #00d2fe);
}



.image {
  width: 80px;
  height: 80px;
  gap: 10px;
  margin-top: 28px;
}

.image p {
  margin-left: 0;
}

.box-txt {
  font-size: 14px;
  font-weight: 400;
}

.txt4 {
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  font-size: 32px;
  color: #ffffff;
}

.txt5 {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 18px;
  margin-left: 3%;
  text-align: center;
  letter-spacing: 2px;
  color: #ffffff;
}

.txt6 {
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  font-size: 10px;
  color: #ffffff;
}

.txt7 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 150%;
  color: #ffffff;
  margin-left: 157px;
  text-align: center;
  letter-spacing: 0.1em;
}

.logo-img {
  width: 140%;
}

.container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container1 {
  width: 100%;
  display: flex;
}

.container3 {
  width: 100%;
  display: flex;

}

.out-box1 {
  width: 270px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(360deg, #cee1fd 8.33%, #ccf6ff 91.67%);
  border-radius: 0px 12px 12px 0px;
  margin-right: 10px;
  border: 2px solid transparent;
  border-image: linear-gradient(45deg, #2279f5, #00d2fe);
  border-image-slice: 1;
}

.out-box2 {
  width: 90%;
  height: 725px;
  margin: 20px auto;
  border-radius: 12px;
  border: none;
  background: url("./Images/homescreen.png"), linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.out-box4 {
  width: 95%;
  height: 880px;
  margin: 40px auto;
  border-radius: 12px;
  border: none;
  background: url("Images/background.png");
  display: flex;
  align-items: center;
  justify-content: center;
}

.out-box3 {
  width: 75%;
  height: 670px;
  margin: 40px auto;
  border-radius: 12px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.out-box3-1 {
  height: 880px;
  margin: 40px auto;
  /*
  border-radius: 12px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center; */

  border-radius: 12px;
  align-items: center;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 59%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.out-box6 {
  width: 100%;
  height: 880px;
  margin: 40px auto;
  border-radius: 12px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 235px;
}

.out-box5 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-clip: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("./Images/login_design.png"), linear-gradient(180deg, #2279F5 8.33%, #00D2FE 91.67%);
}

.css-k008qs {
  width: 100% !important;
}

.contain1 {
  display: flex;
  padding: 30px;
  flex-direction: column;
  /* gap: 30px; */
  width: 50%;
  height: 647px;
  border-radius: 12px;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
}

.contain1-3 {
  display: flex;
  padding: 40px;
  flex-direction: column;
  gap: 30px;
  width: 53%;
  height: 623px;
  border-radius: 12px;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
}

.contain1-2 {
  display: flex;
  padding: 40px;
  flex-direction: column;
  width: 100%;
  height: 673px;
  gap: 30px;
  border-radius: 12px;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
}



.containpinfo {
  /* width: 600px;
  height: 573px;
  box-shadow: 0px 2px 40px rgba(34, 121, 245, 0.15);
  border-radius: 12px;
  background: #ffffff;
  border: "3px solid red" */
  padding: 40px;
  flex-direction: column;
  gap: 30px;
  width: 45%;
  height: 673px;
  border-radius: 12px;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);


}

.containpinfodisplay {
  width: 450px;
  height: auto;
  box-shadow: 0px 2px 40px rgba(34, 121, 245, 0.15);
  border-radius: 12px;
  background: #ffffff;
  border: "3px solid red"

}


.contain11 {
  width: 100%;
  height: 85%;
  box-shadow: 0px 2px 40px rgba(34, 121, 245, 0.15);
  border-radius: 12px;
  background: #ffffff;
}

.box1 {
  width: 100%;
  max-width: 200px;
  height: 168px;
  padding: 28px, 40px, 28px, 40px;
  gap: 10px;
  border: none;
  box-shadow: 0px 2px 40px rgba(34, 121, 245, 0.15);
  border-radius: 12px;
  background-color: #ffffff;
  margin-left: 35px;
  margin-right: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
}

.img-bg {
  background: #ffffff;
  border-radius: 40px;
}

.start {
  margin-top: 100px;
  background: linear-gradient(360deg, #2279f5 8.33%, #00d2fe 91.67%);
}

.start p {
  color: #ffffff;
}

.mid {
  margin-top: 40px;
  margin-bottom: 40px;
}

.ch_txt {
  color: #ffffff;
  font-weight: 600;
  font-size: 10px;
  text-align: center;
}




.in-box {
  height: 536px;
}

.txt8 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
}

select.inpt {
  width: 100%;
  height: 42px;
  gap: 10px;
  border: 1px solid #c4cbd4;
  border-radius: 12px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 12px;
}

select.inpt {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../src/Icons/caret-down-light.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
}

.inpt {
  width: 100%;
  height: 42px;
  gap: 10px;
  border: 1px solid #c4cbd4;
  border-radius: 12px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 12px;
}

.inpt1 {
  width: 100%;
  height: 42px;
  padding: 13px 16px;
  gap: 10px;
  border: 1px solid #c4cbd4;
  border-radius: 12px;
  box-sizing: border-box;


}

.inpt1:focus {
  border: 1px solid #3689f5;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 42px !important;
  border-radius: 12px !important;
}


.inpt-box {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.btn1 {
  margin-top: 40px;
  width: 100%;
  height: 48px;
  background: linear-gradient(360deg, #2279f5 8.33%, #00d2fe 91.67%);
  border: none;
  border-radius: 12px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.4s ease;

}
.btn1:hover {
  transform: scale(1.01);
  box-shadow: 0 0 15px rgba(0, 210, 254, 0.5);
}
.table-animation{
  transition: all .3s ease;
  cursor: pointer;

}
.table-animation:hover{
  transform: scale(1.01);
  box-shadow: 0 0 15px rgba(0, 210, 254, 0.5);

}
.scanbtn {
  font-size: 14px;
  margin-right: 10px;
  width: 90px;
  background: linear-gradient(360deg, #2279f5 8.33%, #00d2fe 91.67%);
  border: none;
  border-radius: 12px;
  color: #ffffff;
  height: 44px;
  transition: all 0.4s ease;


}
.scanbtn:hover {
  transform: scale(1.01);
  box-shadow: 0 0 15px rgba(0, 210, 254, 0.5);
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.emb-box {
  border-radius: 12px;
  width: 97%;
  margin: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background: #ffffff;
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}





.emb-box2 {
  width: 75%;
  height: 82%;
}

.box5 {
  height: 100%;
  width: 17%;
  position: relative;

}

#txt10 {
  width: 109px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 0px;
}

.box5 img {
  width: 180px;
  height: 160px;
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 40px rgba(34, 121, 245, 0.15));
  border-radius: 12px;
}

.image-name {
  color: #6c7c93;
  font-size: 14px;

}

.para-class {
  color: var(--Neutral-Black, #010B18);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.emb {
  display: flex;
  justify-content: center;
  margin: 40px auto;
}

/* .emb-box3 {
  width: 1312px;
  height: 402px;
  border: 8px solid #47D273;
  box-shadow: 0px 2px 20px #47D273;
  border-radius: 12px;
  margin: 24px;
  display: flex;
  background: #ffffff;
  
} */

.emb-flex {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 12px;
  margin: 24px;
}

.emb-container {
  height: 100%;
  /* background: linear-gradient(360deg, #cee1fd 8.33%, #ccf6ff 91.67%); */
  background: #99EDFF;
  border-radius: 12px;
  margin: auto;
  width: 97%;
  min-height: 526px;

}

.flex-box {
  display: flex;
  justify-content: space-between;
  margin-top: 33px;
  padding-top: 27px;
  width: 98%;
  align-items: center;
}

.filter {
  display: flex;
  align-items: center;
  width: 37%;
  gap: 25px;
  justify-content: flex-end;

}

.flex-mid {
  margin-right: 9px;
}

.filter__box {
  width: 40px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #cee1fd;
  border-radius: 0px 12px 12px 0px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: "pointer";
  transition: " transform 0.3s ease",
}
.filter__box:hover{
transform: scale(1.1);

}

.widget {
  margin-right: 24px;
  width: 56%;
}

.filter__widget span {
  margin-right: 8px;
  color: #6c7c93;
  font-weight: 400;
  font-size: 12px;
}

.filter__widget .select {
  padding: 9px 20px;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  background: #ffffff;
  color: #6c7c93;
  border-right: 16px solid transparent;
  font-size: 12px;
  line-height: 14px;
}

.filter__widget select:focus {
  outline: none !important;
}

.filter__widget select option {
  font-size: 0.9rem;
}

.btn2 {
  height: 44px;
  padding: 13px, 20px, 13px, 20px;
  background: linear-gradient(360deg, #2279f5 8.33%, #00d2fe 91.67%);
  box-shadow: 0px 2px 40px rgba(34, 121, 245, 0.15);
  border-radius: 12px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  border: none;
  width: 117px;
  padding: 10px;
}

.inpt4 {
  width: 100%;
  height: 90px;
  padding: 13px 16px 13px 16px;
  border: 1px solid #c4cbd4;
  border-radius: 12px;
  box-sizing: border-box;
  outline: none;
}

.edit {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #cee1fd;
  padding-right: 20px;
}

.btn3 {
  width: 65px;
  margin-bottom: 2px;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #2279f5;
  border-radius: 12px;
}

.btn-txt {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: "normal";
  color: #2279f5;
}

.score-bar {
  height: 50%;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.score-bar1 {
  height: 50%;
  width: 25%;

}

.btn-txt1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
  color: #ffffff;
}

.error-text {
  margin-bottom: -12px;
  font-size: 12px;
}

.inpt3 {
  width: 189.25px;
  height: 45px;
  padding: 10px 16px;
  border: 1px solid #c4cbd4;
  border-radius: 12px;
  box-sizing: border-box;
  outline: none;
  margin-right: 5px;
  background: white;
  display: flex;
  align-items: center;
  gap: 30px;
}

select.inpt3 {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../src/Icons/caret-down-light.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
}

.inpt5 {
  width: 125px;
  height: 44px;
  padding: 10px 8px;

  border: 1px solid #c4cbd4;
  border-radius: 12px;
  box-sizing: border-box;
  outline: none;
  margin-right: 10px;
  cursor: "pointer";
}

select.inpt5 {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../src/Icons/caret-down-light.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
  margin-right: 10px;
  cursor: "pointer";
}

.inpt6 {
  width: 90px;
  height: 44px;
  padding: 10px 8px;

  border: 1px solid #c4cbd4;
  border-radius: 12px;
  box-sizing: border-box;
  outline: none;
  margin-right: 10px;
  cursor: "pointer";
}

select.inpt6 {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../src/Icons/caret-down-light.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
  margin-right: 10px;
  cursor: "pointer";
}

.box6 {
  height: 100%;
  width: 20%;
  position: absolute;
  left: 20px;
}

.box6 img {
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 40px rgba(34, 121, 245, 0.15));
  border-radius: 12px;
}

/* .emb-box3 {
  width: 1312px;
  height: 402px;
  border: 8px solid #47D273;
  box-shadow: 0px 2px 20px #47D273;
  border-radius: 12px;
  margin: 24px;
  display: flex;
  background: #ffffff;
  
} */

@media only screen and (max-width: 1000px) {
  .emb-box {
    border: 2px solid green;
    margin: 14px;
    display: flex;
    background: #ffffff;
  }

  .emb-box .box5 {
    width: 130px;
    height: 100%;
  }

  .emb-box .box5 img {
    width: 120px;
    height: 120px;
    box-sizing: border-box;
    border: 1px solid #cee1fd;
    filter: drop-shadow(0px 2px 40px rgba(34, 121, 245, 0.15));
    border-radius: 8px;
  }

  /* .emb-box2{
    width: 880px;
    height: 254px;
  } */
  .score-bar {
    height: 100%;
    width: 20%;
    margin-left: 10px;
    margin-right: 40px;
  }

  .txt12 {
    font-weight: 300;
    font-size: 6px;
    line-height: 10px;
    text-align: center;
    padding: 2px;
  }



  .inpt3 {
    width: 100px;
    height: 35px;
    border: 1px solid #c4cbd4;
    border-radius: 12px;
    box-sizing: border-box;
    outline: none;
  }

  .inpt4 {
    height: 50px;
    padding: 13px 16px 13px 16px;
    border: 1px solid #c4cbd4;
    border-radius: 12px;
    box-sizing: border-box;
    outline: none;
  }

  .score-bar1 {
    height: 50%;
    width: 20%;
    margin-left: 50px;
  }

  .score-bar {
    height: 50%;
    width: 20%;
    margin-left: 50px;
  }
}

.emb-box3 {
  width: 1312px;
  height: 402px;
  border: 8px solid #47d273;
  box-shadow: 0px 2px 20px #47d273;
  border-radius: 12px;
  margin: 24px;
  display: flex;
  background: #ffffff;
}

.inpt3 {
  height: 45px;
  border: 1px solid #c4cbd4;
  border-radius: 12px;
  box-sizing: border-box;
  outline: none;
}

.inpt4 {
  height: 90px;
  padding: 13px 16px 13px 16px;
  border: 1px solid #c4cbd4;
  border-radius: 12px;
  box-sizing: border-box;
  outline: none;
}

.edit1 {
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  margin-top: 60px;
  justify-content: flex-end;
  border-top: 1px solid #cee1fd;
}

.tbl-cell {
  display: table-cell;
  table-layout: fixed;
  padding-right: 25px;
  margin: 5px;
}

.btn4 {
  height: 32px;
  margin-bottom: 2px;
  background: #2279f5;
  box-sizing: border-box;
  border: 1px solid #2279f5;
  border-radius: 12px;
  padding-left: 5px;
  padding-right: 12px;
}

.btn-txt1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}

.box5 .emb-img1 {
  width: 40px;
  height: 32px;
  margin-right: 10px;
  padding: 7px 12px 7px 12px;
  box-sizing: border-box;
  border: 1px solid #cee1fd;
  background: #ffffff;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
}

.emb-img {
  cursor: pointer;

}

.txt9 {
  color: #6c7c93;
  left: 40px;
  margin-left: 20px;
}

.embryo-heading {
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  margin-left: 2%;
}

.txt12 {
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding: 5px;
}

.txt10 {
  border-radius: 8px 0px;
  width: 64%;
  padding-bottom: 5px;
  padding-top: 5px;
  position: absolute;
  z-index: 6;
  text-align: center;
}

.para {
  margin-bottom: 0px;
  color: #6c7c93;
}

input[type="radio"]:checked+label {
  font-weight: bold;
  color: "black";
}

label {
  color: #6c7c93;
}

input[type="date"]::placeholder {
  color: #6c7c93;
}

.about {
  background: rgba(34, 121, 245, 0.9);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  color: #ffffff;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 565px;
  height: 473px;
  position: absolute;
  top: 50%;
  left: 42%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.warning {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-section {

  width: 88vw;
  height: 765px;
  padding-right: 130px;
}

.about-details {
  font-weight: 400;
  font-size: 20px;
  line-height: 170%;
  text-align: center;
}

.version {
  text-align: center;
  padding: 20px;
  text-decoration: underline;
  font-size: 14px;
}

.help-section {

  width: 88vw;
  height: 770px;
  padding-right: 130px;

}

.inpt-text {
  width: 100%;
  gap: 10px;
  border: 1px solid #c4cbd4;
  border-radius: 12px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 12px;
}

.btn-help {
  margin-top: 40px;
  height: 48px;
  background: linear-gradient(360deg, #2279f5 8.33%, #00d2fe 91.67%);
  border: none;
  border-radius: 12px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
}

.help-box {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.help-box2 {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 80px;
  margin-bottom: 40px;
  background: #FFFFFF;
  border-radius: 12px;
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search-cycle-id::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  /* Neutral/50 */
  color: #6C7C93;
  padding-left: 15px;
}

.css-1bn53lx {
  height: 42px !important;
  border-radius: 12px !important;
}

.search-cycle-id {
  width: 240px !important;
  height: 44px !important;
  /* border: none; */
  border-radius: 12px;
  padding: 11px 12px !important;
}

.pre-txt {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  line-height: 160%;
  color: #2B323B
}

.notification-container {
  top: unset !important;
  /* Remove the default top positioning */
  bottom: 12px !important;
  /* Position it at the bottom */
  right: 12px !important;
  /* Position it to the right */
}

.notification-item {
  color: black !important;
}

.notification-title {
  color: black !important;
}

.notification-message {
  color: black !important;
}

.notification-success {
  background-color: rgb(255, 254, 254) !important;
}

.notification-success .notification-icon {
  color: green !important;
}

.notification::before {
  color: green !important;
}


.patient-box {
  background-color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  /* Optional shadow for aesthetics */
}

.patientinfo-label {
  font-size: 16px;

}

.patientinfo-value {
  font-size: 16px;
  font-weight: bold;
  color: #2279F5;

}

.sort-filter {
  display: flex;
  align-items: center;
}


.quality-score {
  display: flex;
  height: 44px;
  padding: 0px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 12px 0px 0px 12px;
  border: 1px solid var(--Primary-10, #CEE1FD);
  background: var(--Neutral-White, #FFF);
  margin-left: 25px;
  width: 140px;
}

.quality {
  display: flex;

}

.sort {
  color: var(--Neutral-50, #6C7C93);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popup-btn {
  width: 107px;
  height: 48px;
  padding: 14.5px, 40px, 14.5px, 40px;
  border-radius: 12px;
  color: #2279F5;
  background: linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%);
  border: none;
}

.popup-btn1 {
  width: 200px;
  height: 48px;
  padding: 14.5px, 40px, 14.5px, 40px;
  border-radius: 12px;
  color: #fff;
  background: linear-gradient(360deg, #2279F5 8.33%, #00D2FE 91.67%);
  border: none;
}

.popup-div {
  display: flex;
  width: 245px;
  justify-content: space-between;
  margin-top: 28px;
  margin-left: 135px;
}

.popup-div1 {
  display: flex;
  width: 245px;
  justify-content: space-between;
  margin-top: 28px;
  margin-left: 164px;
}


.popup-btn:hover {
  background: linear-gradient(360deg, #2279F5 8.33%, #00D2FE 91.67%);
  color: #fff;
}

@media only screen and (max-width: 1550px) {
  .out-box2 {
    height: 645px;
  }

  .contain1 {

    width: 50%;
    height: 614px;

  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 35px !important;
  }

  .css-1bn53lx {
    height: 35px !important;

  }

  .inpt1 {
    height: 35px;
  }

  .inpt {

    height: 42px !important;

  }

  .about {
    left: 46%;
    width: 490px;
    height: 430px;
  }

  .out-box {
    height: 590px;
  }

  .txt-container {
    height: 160px;
  }

  .box-container {
    height: 260px;
    width: 700px;
    margin-top: 44px;
  }

  .txts {
    margin-top: 37px;
  }

  .about-section {
    height: 660px;
  }

  .about-details {
    font-size: 17px;
  }

  .help-section {
    height: 650px;
  }

  .contain1-2 {
    width: 681px;
  }
}

@media only screen and (max-width: 1420px) {
  .filter {
    width: 40%;
  }
}

@media only screen and (max-width: 1366px) {
  .about {
    left: 47%;
    width: 480px;
    height: 400px;
  }

  .about-details {
    font-size: 18px;
  }

  .filter {
    width: 44%;
  }
}

@media only screen and (max-width: 1260px) {
  /* .filter{
    width: 40%;
  } */
}

@media only screen and (max-width: 1024px) {}



@media only screen and (max-width: 988px) {}

@media only screen and (max-width: 824px) {
  .login-div {
    display: block;
  }

}

.head-text {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 184.226px;
  height: 27.338px;
  flex-shrink: 0;
  color: var(--Neutral-White, #FFF);
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  width: 100%;

}

.CircularProgressbar .CircularProgressbar-text {
  font-size: 15px !important;
  font-weight: bold !important;
}

.CircularProgressbar-path {
  stroke: rgb(34, 121, 245) !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  /* width: 189.25px !important;   */
  height: 43px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #c4cbd4 !important;
}

.css-1bn53lx {
  /* width: 189.25px !important; */
  height: 43px !important;
}
.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: #c4cbd4 !important;
}
.datepicker{
  width: 189.25px !important;

}