html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

nav {
  background-color: #2279f5;
  padding: 1rem 1rem;
}

.navbar3 {
  display: flex;
  align-items: center;
}

.navbar1 {
  display: flex;
  justify-content: flex-start;
}
.navbar1 img{
  margin:5px
}

.brand-txt {
  margin-left: 2%;
  color: #fff;
}

.txt1 {
  font-weight: bold;
  letter-spacing: 1px;
}

.txt2 {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: x-small;
  margin-left: 3%;
}

.txt3 {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 8px;
}

.navbar2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}



.avatar {
  display: inline-block;
  margin-bottom: 0;
  height: 3rem;
  width: 3rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-right: 15px;
}

.rounded-circle {
  border-radius: 50% !important;
}

.avatar-nav {
  height: 2.2rem;
  width: 2.2rem;
  margin-right: 10px;
}