.success-msg-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  padding: 32px;
  border-radius: 12px;
  width: 400px;
  height: 219px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid black;
}
.saved-msg {
  font-weight: 400;
  font-size: 16px;
  color: #6c7c93;
}
.success-msg {
  font-weight: 700;
  font-size: 24px;
  color: #010b18;
}
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: transparent;
  border: none;
  outline: none;
background: #FFFFFF;
border: 1px solid #CEE1FD;
border-radius: 50%;
}
.image-box{
  border-radius: 12px;
  width: 794.44px;
  height: 650px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;

filter: drop-shadow(0px 2px 40px rgba(34, 121, 245, 0.15));
}
.image-box1{
  border-radius: 12px;
  width: 240px;
  height: 86;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
filter: drop-shadow(0px 2px 40px rgba(34, 121, 245, 0.15));
}
.image-box img{
  width: 100%;
}

.custom-select {
  position: relative;
  width: 200px;
}

.select-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.select-trigger:hover {
  background-color: #e3e3e3;
}

.arrow {
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.custom-select.open .arrow {
  transform: rotate(-135deg);
}

.options {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  
}

.custom-select.open .options {
  
}

.option {
  padding: 10px;
  cursor: pointer;
  list-style: none;
}

.option:hover {
  background-color: #e3e3e3;
}




