.login-div {
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.login-image-div {
    width: 110%;
    margin: 0 auto;
}

.img-image {
    width: 100%;
    height: 100%;
}
.login-container {
    width: 100%;
    display: flex;

}

.login-container-img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-clip: content-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url("../../Images/login_design.png"), linear-gradient(180deg, #2279F5 8.33%, #00D2FE 91.67%);

}

.head-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;
    height: 230px;
    margin-top: 60px;
}

.head-box {
    width: 80%;
    display: contents;
}

.head {
    color: var(--Neutral-White, #FFF);
    font-family: Roboto;
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.img-div-logo {
    width: 10%;
}

.img-div {
    width: 40%;
}

.svg-container {
    color: var(--Neutral-White, #FFF);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -29px;
    letter-spacing: 3px;
}

.contain12 {
    display: flex;
    padding: 40px;
    flex-direction: column;
    gap: 30px;
    width: 75%;
    height: 500px;
    border-radius: 12px;
    background: var(--Neutral-White, #FFF);
    box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
}


.txt8-login {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
}



.inpt-login {
    width: 100%;
    height: 42px;
    gap: 10px;
    border: 1px solid #c4cbd4;
    border-radius: 12px;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 12px;
}

select.inpt-login {
    -webkit-appearance: none;
    appearance: none;
    background-image: url("../../../src/Icons/caret-down-light.svg");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
}

.btn1-login {
    margin-top: 40px;
    width: 100%;
    height: 48px;
    background: linear-gradient(360deg, #2279f5 8.33%, #00d2fe 91.67%);
    border: none;
    border-radius: 12px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    transition: all 0.3s ease;
}

.btn1-login:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(0, 210, 254, 0.5);
}

.forgot-password-div {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.forgot-password {
    color: rgba(34, 121, 245, 1);
    text-decoration: underline;
    cursor: pointer;
}

.forgot-modal {
    border: none !important;
    border-radius: 12px !important;

}

.btn-forgot {
    margin-top: 40px;
    width: 100%;
    height: 48px;
    background: linear-gradient(360deg, #2279f5 8.33%, #00d2fe 91.67%);
    border: none;
    border-radius: 12px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
}

.success {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 8px;
}

@media only screen and (max-width: 1540px) {
    .contain12 {
        padding: 40px;
        gap: 24px;
        width: 536;
        height: 440px;
        border-radius: 12px;
        background: var(--Neutral-White, #FFF);
        box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
    }

    .head-div {
        gap: 23px;
        height: 174px;
        margin-top: 50px;
    }
}